<h1>{{ 'home.title' | translate}}</h1>

<div class='row-image-teaser'>
  <div class='image-teaser-s' *ngIf="authService.canViewEfoKachel() | async" (click)="navigateToEFo()">
    <span class='teaser-label' translate="kachel.efo.titel"></span>
    <p class='teaser-small' translate="kachel.efo.beschreibung"></p>
  </div>
  <div class='image-teaser-s-2' *ngIf="(authService.canViewHPVKachel() | async) && isHpvActive()" (click)="navigateToHPV()">
    <span class='teaser-label' translate="kachel.hpv.titel"></span>
    <p class='teaser-small' translate="kachel.hpv.beschreibung"></p>
  </div>
  <div class='row-image-teaser-berechtigung'>
    <div class='image-teaser-berechtigung' *ngIf="authService.isLoggedIn()" (click)="navigate('/berechtigung/beantragen')">
      <span class='teaser-label' translate="kachel.berechtigung.beantragen.titel"></span>
      <p class='teaser-small' translate="kachel.berechtigung.beantragen.beschreibung"></p>
    </div>
  </div>

</div>
<div class="row-image-teaser">
  <div (click)="loginBeLogin()" class='image-teaser-anmeldung-be-login' *ngIf="!authService.isLoggedIn()">
    <span class='teaser-label' translate="kachel.belogin.titel"></span>
    <p class='teaser-small' translate="kachel.belogin.beschreibung"></p>
  </div>
  <div (click)="authService.login()" class='image-teaser-anmeldung' *ngIf="!authService.isLoggedIn()">
    <span class='teaser-label' translate="kachel.anmeldung.titel"></span>
    <p class='teaser-small' translate="kachel.anmeldung.beschreibung"></p>
  </div>
</div>
