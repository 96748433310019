import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from "../auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from 'src/environments/environment.prod';
import {OAuthService} from "angular-oauth2-oidc";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {ApplicationService} from "../common/application.service";
import {ApplikationEnum} from "../core/api/model/applikationEnum";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  private currentLang: String;
  private languageChangeSubscription: Subscription;
  public authService: AuthenticationService;

  constructor(authService: AuthenticationService, public router: Router, route: ActivatedRoute, private oauth: OAuthService, private translateService: TranslateService,
              private applikationService: ApplicationService) {
    this.authService = authService;
    const relogin = route.snapshot.queryParamMap.get("rl");
    if (relogin && !authService.userLoggedIn) {
      authService.relogin();
    }
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((changeEvent) => {
        this.currentLang = changeEvent.lang;
      }
    );
  }

  navigateToEFo() {
    window.location.href = environment.efoUrl + "?rl=true&lang=" + this.currentLang;
  }

  navigateToHPV() {
    window.location.href = environment.hpvUrl + "?rl=true&lang=" + this.currentLang;
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  loginBeLogin() {
    this.oauth.initImplicitFlow(null, {kc_idp_hint: "be-login"});
  }

  public isHpvActive(): boolean {
    return this.applikationService.isApplicationActive(ApplikationEnum.HPV);
  }

  ngOnDestroy(): void {
    this.languageChangeSubscription.unsubscribe();
  }
}
