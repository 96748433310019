import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../auth/auth.service";
import {InfoService} from "../core/api/api/info.service";
import {TranslateService} from "@ngx-translate/core";

declare var portalFrontendVersion: String;


@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

  public portalBackendVersion: String;

  constructor(private authService: AuthenticationService, private infoService: InfoService, private translateService: TranslateService) {
    authService.userLoggedIn$.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.infoService.getVersionNumber().subscribe(
          success => this.portalBackendVersion = success.version
        );
      }
    });
  }

  ngOnInit() {
  }

  getFrontendVersion() {
    return portalFrontendVersion;
  }

  getBackendVersion() {
    return this.portalBackendVersion;
  }

  getGSIKantonBernKontaktUrl() {
    return "https://www.gef.be.ch/gef/" + this.translateService.currentLang + "/index/direktion/ueber-die-direktion/kontakt.html";
  }

  getGSIKantonBernImpressumUrl() {
    return "https://www.gef.be.ch/gef/" + this.translateService.currentLang + "/tools/impressum.html";
  }

  getGSIKantonBernRechtlichesUrl() {
    return "https://www.be.ch/portal/" + this.translateService.currentLang + "/tools/rechtliches.html";
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }
}
