import {Component, OnInit} from '@angular/core';
import {AntragBerechtigungDTO, BenutzerDTO, BenutzerRolleDTO, BenutzerService, BetriebDTO, BetriebeService} from "../../core/api";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../auth/auth.service";
import ApplikationEnum = AntragBerechtigungDTO.ApplikationEnum;

@Component({
  selector: 'app-betriebe-list',
  templateUrl: './betriebe-list.component.html',
  styleUrls: ['./betriebe-list.component.scss']
})
export class BetriebeListComponent implements OnInit {

  betriebe: BetriebDTO[];
  benutzer: BenutzerDTO;

  filter: String;
  key: string = ''; // default sorting column
  reverse: boolean = false;
  page: number = 1;
  itemsPerPage: number = 10;
  hiddenApplikationen: Array<ApplikationEnum>;

  constructor(public authService: AuthenticationService, private betriebeService: BetriebeService, private router: Router,
              private benutzerService: BenutzerService, private translateService: TranslateService) {
    this.hiddenApplikationen = [ApplikationEnum.GEFPORTAL];
  }

  ngOnInit() {
    this.benutzerService.self().subscribe(
      b => {
        this.benutzer = b;
      }
    );
  }

  public onApplikationChanged(applikation: ApplikationEnum) {
    if (this.isBenutzerAdmin()) {
      this.betriebeService.getBetriebe(applikation).subscribe(
        success => {
          this.betriebe = success;
          this.initBetriebeForSearchModule();
        }
      );
    } else {
      this.setBetriebAuswahlForBetriebsleiter();
    }
  }

  private setBetriebAuswahlForBetriebsleiter() {
    this.betriebe = new Array<BetriebDTO>();
    let blRollen: Array<any> = this.benutzer.rollen.filter(
      r => r.rolle === ("EFO_BETRIEBSLEITER")
    );
    blRollen.forEach(rolle => {
      this.betriebeService.getBetriebById(rolle.betrieb.id).subscribe(
        success => this.betriebe.push(success)
      )
    });
    this.initBetriebeForSearchModule();
  }

  private initBetriebeForSearchModule() {
    this.betriebe.forEach(betrieb => this.initBetriebForSearchModule(betrieb));
  }

  /**
   * Ng2SearchPipeModule searches every field of an object. That's why those fields, we do not wish to be searched, have to be set to undefined.
   * betrieb.id is necessary for the navigation, so it will not be set to undefined.
   * the translation of an enum needs to be added as a new field and betriebArt set to undefined for the searchModule to look through the translation, instead of the technical enum name.
   */
  private initBetriebForSearchModule(betrieb: BetriebDTO) {
    betrieb.bur = undefined;
    betrieb.email = undefined;
    betrieb.gln = undefined;
    betrieb.name2 = undefined;
    betrieb.postfach = undefined;
    betrieb.telefon = undefined;
    betrieb.uid = undefined;
    betrieb.betriebArtTranslation = this.translateService.instant('enum.betriebart.' + betrieb.betriebArt);
    betrieb.betriebArt = undefined;
  }

  showDetails(betrieb) {
    this.router.navigate(['/betriebe', betrieb.id]);
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  isBenutzerAdmin() {
    if (this.benutzer == undefined) {
      return false;
    }
    let adminRollen: Array<any> = this.benutzer.rollen.filter(
      r => r.rolle === BenutzerRolleDTO.RolleEnum.EFOADMIN || r.rolle === BenutzerRolleDTO.RolleEnum.ADMINISTRATOR || BenutzerRolleDTO.RolleEnum.HPVADMIN
    );
    return adminRollen.length > 0;
  }
}
