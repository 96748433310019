import {Injectable} from '@angular/core';
import {ApplikationEnum} from "../core/api/model/applikationEnum";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private activeApplications: Array<any>;

  constructor() {
    let activeApplicationStringArray = environment.activeApplications;
    if (activeApplicationStringArray) {
      this.activeApplications = activeApplicationStringArray.map(a => ApplikationEnum[a]);
    } else {
      this.activeApplications = Object.values(ApplikationEnum).map(ae => ae);
    }
  }

  public isApplicationActive(applikationEnum: ApplikationEnum): boolean {
    return this.activeApplications.includes(applikationEnum);
  }
}
